button {
  background-color: #42428d;
  border-radius: 8px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  font-size: 1.1em;
  font-weight: 600;
  /*margin-top: 12px;*/
  border-width: 0px;
  /*margin: 12px 12px;*/
  border-radius: 3px;
}

.web3-button {
  background-color: #42428d;
  border-radius: 8px;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  font-size: 1.1em;
  font-weight: 600;
  /*margin-top: 12px;*/
  border-width: 0px;
  /*margin: 12px 12px;*/
  border-radius: 3px;
}

.web3-button.warning {
  background-color: #f2a900;
}

.web3-button.warning:hover {
  background-color: #ecb73b;
}

.col-2 {
  display: grid;
  grid-template-columns: auto auto;
}

button:hover {
  background-color: #5a5ab2;
  cursor: pointer;
}

.app-container {
  text-align: center;
  background-color: #1e2229;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: #cbcbcb;
}

.app-container.left {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-header {
  margin-top: 24px !important;
  letter-spacing: 3px;
  font-weight: bolder;
  color: lightblue;
}


.component-wrapper {
 /*padding: 24px;*/
  padding: 0px;
  background-color: #161a1e;
  /*border: solid 2px #21252a;*/
  border-radius: 6px;
  display: inline-grid;
  gap: 0px;
  grid-template-columns: auto;
  margin: 12px;
  max-width: 548px;
}

.component-wrapper>.header {
  padding: 12px;
  font-family: 'Courier New', Courier, monospace;
}

.component-wrapper>.content {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #1b1d1e;
  padding: 12px;
  border-bottom: solid 1px #3d454f;
  font-family: 'Courier New', Courier, monospace;
}

.component-wrapper>div:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.component-wrapper>div:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.component-section {
  background-color: rgba(0,0,0,0.4);
}

.grid-container {
  display: inline-grid;
  gap: 10px;
  padding: 10px;
  grid-template-columns: auto auto;
}

.grid-container > .grid-item {
  text-align: center;
  padding: 20px 0;
  margin: auto;
}

.grid-item > h3 {
  font-weight: 600;
  margin: auto;
  font-size: 1.6em;
}
.grid-item.text {
  margin-top: 12px;
}



table {
  margin: auto;
    width: 100%;
    justify-content: space-between;
}
table > tbody > tr {
  padding-bottom: 4px;
}
table > tbody > tr > td:first-child {
  text-align: left;
}
table > tbody > tr > td:last-child {
  text-align: right;
}
table > tbody > tr > td {
  padding: 4px;
}

.nft-name {
  margin: 6px 0px;
  padding: 18px;
}

.qr-code {
  padding: 12px;
  width: 256px;
}

.grid-item {
  border-radius: 0px;
  animation: fadeIn 1s;
  /*padding: 24px 0px;*/
}

.grid-item.dark {
  background-color: #1b1d1e;
}

.extra-padding {
  padding-top:24px;
  padding-bottom: 24px;
}

.grid-item.qr-container {
  border-radius: 0px;
  animation: fadeIn 1s;
}

.grid-item.send-buttons-container {
  /*background-color: #1a1c1e;*/
  border-radius: 0px;
  animation: fadeIn 1s;
  margin-top: 18px;
}

.grid-item.send-buttons-container > .web3-button {
  margin: 12px;
  width: 100%;
}

.grid-item.nft-data-container { 
  border-radius: 0px;
  animation: fadeIn 1s;
  /*padding: 24px 0px;*/
}

.grid-item.nft-data-container > img {
  width: 80%

}

.grid-item.nft-data-container > .nft-description {
  text-align: left;
  max-width: 70%;
  margin: auto;
  padding: 12px 0px;
  padding-top: 24px;
}

.grid-item.button-group {
  display: grid;
  margin: 0px;
}
.grid-item.button-group > button {
  margin: 24px;
  
}

.grid-item>.icon-button {
  margin: 12px;
}
.status-container {
  padding: 24px 24px;
  /*background-color: #1b1d1e;*/
  animation: fadeIn 1s;
  /*margin: 0px 24px;*/
}

.response-icon {
  width: 192px;
  margin: auto;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.ui.modal.transition.visible.active {
  background-color: #e9ebef;
  background: #e9ebef;
}
.ui.modal.transition.visible.active>div {
  background-color: #e9ebef;
  background: #e9ebef;
}

@media screen and (min-width: 768px) {
  .web3-message {
    width: 100%;
    max-width: 548px;
  }
}

@media screen and (max-width: 768px) {
  .web3-message {
    width: 100%;
    max-width: 548px;
  }
}

@media screen and (max-width: 548px) {
  .grid-container {
    width: 100%;
    max-width: 380px;
  }
}

.web3-message {
  /*display: grid;
  grid-template-columns: auto auto;*/
  position: relative;
  padding: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  
  
  border: solid 2px;
  border-radius: 3px;
  margin: 24px auto;
  background-color: rgba(0,0,0,0.5);
}

.web3-message > p {
  padding-left: 32px;
  font-size: 18px;
  font-family: monospace;
}

.web3-message > svg {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  top: 0px;
  padding: 9px 8px;
  transition: 0.3s;
}

.web3-message.info > p {
  font-size: 14px;
  text-align: left;
}

.web3-message.small > p {
  font-size: 14px;
}


.web3-message.small > svg {
  left: 6px;
  top: 4px;
  width: 38px;
  height: 38px;
  padding: 6px 6px;
}

.web3-message > p.small-text {
  font-size: 14px;
}


.web3-message.error {
  border-color: lightcoral;
}
.web3-message.error > p {
  color: lightcoral;
}
.web3-message.error > svg {
  color: lightcoral;
}

.web3-message.warning {
  border-color: orange;
}
.web3-message.warning > p {
  color: orange;
}
.web3-message.warning > svg {
  color: orange;
}

.web3-message.info {
  border-color: lightblue;
}
.web3-message.info > p {
  color: lightblue;
}
.web3-message.info > svg {
  color: lightblue;
}

.web3-message.success {
  border-color: lightgreen;
}
.web3-message.success > p {
  color: lightgreen;
}
.web3-message.success > svg {
  color: lightgreen;
}


.web3-message:first-child {
  justify-content: end;
}
.web3-message:last-child {
  justify-content: start;
}

#get-metamask-link {
  padding-top: 24px;
  padding-bottom: 24px;
}

#get-metamask-link:hover {
  cursor: pointer;
  background-color: #21252a;
}


form {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 12px;
  margin: 24px;
  vertical-align: middle;
}

form > input {
  background-color: #1b1d1e;
  color: #e9ebef;
  border-color: #e9ebef;
}

form > select {
  background-color: #1b1d1e;
  color: #e9ebef;
}


form > label {
  text-align: left;
  margin: auto;
}

input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.purchase-details-container {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 48px;
  background-color: #23282d;
  padding: 12px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  border: solid 2px lightblue;
}